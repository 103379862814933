@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --background-color: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  /* Manual preflight */

  *,
  ::before,
  ::after {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: #e5e7eb;
  }

  ::before,
  ::after {
    --tw-content: '';
  }

  button {
    background-color: transparent;
    background-image: none;
  }

  button,
  [role='button'] {
    cursor: pointer;
  }

  /*
  1. Use a consistent sensible line-height in all browsers.
  2. Prevent adjustments of font size after orientation changes in iOS.
  3. Use a more readable tab size.
  4. Use the user's configured `sans` font-family by default.
  5. Use the user's configured `sans` font-feature-settings by default.
  6. Use the user's configured `sans` font-variation-settings by default.
  */

  html {
    line-height: 1.5; /* 1 */
    -webkit-text-size-adjust: 100%; /* 2 */
    -moz-tab-size: 4; /* 3 */
    tab-size: 4; /* 3 */
    font-family: theme(
      'fontFamily.sans',
      ui-sans-serif,
      system-ui,
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      Roboto,
      'Helvetica Neue',
      Arial,
      'Noto Sans',
      sans-serif,
      'Apple Color Emoji',
      'Segoe UI Emoji',
      'Segoe UI Symbol',
      'Noto Color Emoji'
    ); /* 4 */
    font-feature-settings: theme('fontFamily.sans[1].fontFeatureSettings', normal); /* 5 */
    font-variation-settings: theme('fontFamily.sans[1].fontVariationSettings', normal); /* 6 */
  }

  /*
  1. Remove the margin in all browsers.
  2. Inherit line-height from `html` so users can set them as a class directly on the `html` element.
  */

  body {
    margin: 0; /* 1 */
    line-height: inherit; /* 2 */
  }

  /*
  1. Add the correct height in Firefox.
  2. Correct the inheritance of border color in Firefox. (https://bugzilla.mozilla.org/show_bug.cgi?id=190655)
  3. Ensure horizontal rules are visible by default.
  */

  hr {
    height: 0; /* 1 */
    color: inherit; /* 2 */
    border-top-width: 1px; /* 3 */
  }

  /*
  Add the correct text decoration in Chrome, Edge, and Safari.
  */

  abbr:where([title]) {
    text-decoration: underline dotted;
  }

  /*
  Remove the default font size and weight for headings.
  */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: inherit;
    font-weight: inherit;
  }

  /*
Prevent `sub` and `sup` elements from affecting the line height in all browsers.
*/

  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sub {
    bottom: -0.25em;
  }

  sup {
    top: -0.5em;
  }

  /*
  1. Remove text indentation from table contents in Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=999088, https://bugs.webkit.org/show_bug.cgi?id=201297)
  2. Correct table border color inheritance in all Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=935729, https://bugs.webkit.org/show_bug.cgi?id=195016)
  3. Remove gaps between table borders by default.
  */

  table {
    text-indent: 0;
    border-color: inherit;
    border-collapse: collapse;
  }

  /*
  1. Change the font styles in all browsers.
  2. Remove the margin in Firefox and Safari.
  3. Remove default padding in all browsers.
  */

  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit; /* 1 */
    font-feature-settings: inherit; /* 1 */
    font-variation-settings: inherit; /* 1 */
    font-size: 100%; /* 1 */
    font-weight: inherit; /* 1 */
    line-height: inherit; /* 1 */
    color: inherit; /* 1 */
    margin: 0; /* 2 */
    padding: 0; /* 3 */
  }

  /*
  Removes the default spacing and border for appropriate elements.
  */
  blockquote,
  dl,
  dd,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  hr,
  figure,
  p,
  pre {
    margin: 0;
  }

  fieldset {
    margin: 0;
    padding: 0;
  }

  ol,
  ul,
  menu {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  /*
  Prevent resizing textareas horizontally by default.
  */

  textarea {
    resize: vertical;
  }

  /*
  Make sure disabled buttons don't get the pointer cursor.
  */
  :disabled {
    cursor: default;
  }

  /*
  1. Make replaced elements `display: block` by default. (https://github.com/mozdevs/cssremedy/issues/14)
  2. Add `vertical-align: middle` to align replaced elements more sensibly by default. (https://github.com/jensimmons/cssremedy/issues/14#issuecomment-634934210)
    This can trigger a poorly considered lint error in some tools but is included by design.
  */
  img,
  svg,
  video,
  canvas,
  audio,
  iframe,
  embed,
  object {
    display: block; /* 1 */
    vertical-align: middle; /* 2 */
  }
}

/* root css variables */
:root {
  --main-container-padding-top: 40px;
  --main-container-padding-left: 60px;
  --main-container-padding-right: 60px;
  --main-container-padding-bottom: 60px;

  --main-container-margin: 30px auto;

  --plate-padding-left: 4%;
  --plate-padding-top: 1.5%;
  --plate-padding-right: 4%;
  --plate-padding-bottom: 5%;

  --box-shadow: 0 0 10px rgb(0 0 0 / 0.2);
  --main-padding: 60px;
  --padding: 60px;
  --margin-bottom: 60px;

  --border-width: 1px;
  --background-color-editor: white;
  --margin-header: 0px 0px 0.35em;

  --h1-margin: 32px 0px 0.35em;

  --p-font-size: 1rem;
  --h1-font-size: 2.5rem;
  --h2-font-size: 1.8rem;
  --h3-font-size: 1.5rem;
  --h4-font-size: 1.25rem;
  --h5-font-size: 1rem;
  --h6-font-size: 0.875rem;

  --h1-font-weight: 500;
  --h2-font-weight: 400;
  --h3-font-weight: 400;
  --h4-font-weight: 400;
  --h5-font-weight: 400;
  --h6-font-weight: 500;

  --h1-line-height: 1.167;
  --h2-line-height: 1.2;
  --h3-line-height: 1.167;
  --h4-line-height: 1.235;
  --h5-line-height: 1.334;
  --h6-line-height: 1.6;

  --h1-letter-spacing: -0.01562em;
  --h2-letter-spacing: -0.00833em;
  --h3-letter-spacing: 0em;
  --h4-letter-spacing: 0.00735em;
  --h5-letter-spacing: 0em;
  --h6-letter-spacing: 0.0075em;

  --blockqoute-border-left-style: solid;
  --blockqoute-border-left-width: 2px;
  --blockqoute-border-left-color: lightgray;
  --blockqoute-padding-left: 15px;
  --blockqoute-padding-right: 15px;
  --blockqoute-padding-top: 10px;
  --blockqoute-padding-bottom: 10px;
  --blockqoute-margin-bottom: 10px;
  --blockqoute-color: #aaaaaa;

  --sub1-font-weight: 400;
  --sub1-font-size: 1rem;
  --sub1-line-height: 1.75;
  --sub1-letter-spacing: 0.00938em;

  --sub2-font-weight: 600;
  --sub2-font-size: 0.875rem;
  --sub2-line-height: 1.57;
  --sub2-letter-spacing: 0.00714em;

  --body1-font-weight: 400;
  --body1-font-size: 1rem;
  --body1-line-height: 1.5;
  --body1-letter-spacing: 0.00938em;

  --body2-font-weight: 400;
  --body2-font-size: 0.875rem;
  --body2-line-height: 1.43;
  --body2-letter-spacing: 0.01071em;
}

/* default theme hub, incorrect theme is supplied, Hub theme will be applied */
html,
html[data-theme='Hub'] {
  --font-family-primary: 'Poppins', Helvetica, Arial, sans-serif;
  --font-family-secondary: 'Roboto', Helvetica, Arial, sans-serif;
  --background-theme-color: #E4F2FF;
  --color-primary: #0D47A1;
  --color-secondary: #E91E63;
  --border-color: #388BFF;
  --dark: #2b2b2b;

  --border-radius: 8px;
  --border-style: solid;
  --border-color: #388BFF;
  --p-font-family: 'Open Sans', sans-serif;
}

[data-theme='Pandan'] {
  --font-family-primary: 'Gaegu', Open Sans, Arial, sans-serif;
  --font-family-secondary: 'Didact Gothic', Open Sans, Arial, sans-serif;
  --background-theme-color: #86d347;
  --color-primary: #2b2b2b;
  --color-secondary: #f57e2a;
  --border-color: #f57e2a;
  --dark: #2b2b2b;

  --border-radius: 8px;
  --border-style: solid;
  --border-color: #f57e2a;
  --p-font-family: 'Century Gothic', sans-serif;

  --h1-margin: 0px 0px 0.35em;
}

[data-theme='Lobelia'] {
  --font-family-primary: 'Baloo', Open Sans, Arial, sans-serif;
  --font-family-secondary: 'Poppins', Open Sans, Arial, sans-serif;
  --background-theme-color: #32355d;
  --color-primary: #32355d;
  --color-secondary: #d59b06;
  --dark: #2b2b2b;

  --border-radius: 8px;
  --border-style: solid;
  --border-color: #fab400;
  --p-font-family: 'Open Sans', sans-serif;
}

[data-theme='Warringah'] {
  --font-family-primary: 'Neucha', Open Sans, Arial, sans-serif;
  --font-family-secondary: 'Poppins', Open Sans, Arial, sans-serif;
  --background-theme-color: #4a2278;
  --color-primary: #32355d;
  --color-secondary: #007441;
  --dark: #2b2b2b;

  --border-radius: 8px;
  --border-style: solid;
  --border-color: #7dff8a;
  --p-font-family: 'Open Sans', sans-serif;
}

[data-theme='Indigenous'] {
  --font-family-primary: 'Fredericka the Great', 'Open Sans', 'Arial', sans-serif;
  --font-family-secondary: 'Nunito', 'Open Sans', 'Arial', sans-serif;
  --background-theme-color: #5e4138;
  --color-primary: #8c2703;
  --color-secondary: #f57e2a;
  --border-width: 5px;
  --dark: #2b2b2b;

  --border-radius: 8px;
  --border-style: solid;
  --border-color: #f57e2a;
  --p-font-family: 'Open Sans', sans-serif;

  --main-container-margin: -100px auto 30px auto;
}

[data-theme='Pride'] {
  --font-family-primary: 'Sriracha', 'Open Sans', 'Arial', sans-serif;
  --font-family-secondary: 'Inter', 'Open Sans', 'Arial', sans-serif;
  --background-theme-color: #e0f7fa;
  --color-primary: #770088;
  --color-secondary: #26c6da;
  --border-width: 2px;
  --dark: #2b2b2b;

  --border-radius: 8px;
  --border-style: solid;
  --border-color: #26c6da;
  --p-font-family: 'Open Sans', sans-serif;

  --main-container-margin: -100px auto 30px auto;
}

[data-theme='Diwali'] {
  --font-family-primary: 'Alkatra', 'Open Sans', 'Arial', sans-serif;
  --font-family-secondary: 'Source Sans Pro', 'Open Sans', 'Arial', sans-serif;
  --background-theme-color: #fff59d;
  --color-primary: #512da8;
  --color-secondary: #f57f17;
  --border-width: 3px;
  --dark: #2b2b2b;

  --border-radius: 8px;
  --border-style: solid;
  --border-color: #fbc02d;
  --p-font-family: 'Open Sans', sans-serif;

  --main-container-margin: -100px auto 30px auto;
}

[data-theme='ChineseNewYear'] {
  --font-family-primary: 'Josefin Sans', 'Open Sans', 'Arial', sans-serif;
  --font-family-secondary: 'Source Sans Pro', 'Open Sans', 'Arial', sans-serif;
  --background-theme-color: #c62828;
  --color-primary: #f44336;
  --color-secondary: #f57f17;
  --border-width: 3px;
  --dark: #2b2b2b;

  --border-radius: 8px;
  --border-style: solid;
  --border-color: #ffc107;
  --p-font-family: 'Open Sans', sans-serif;

  --main-container-margin: -100px auto 30px auto;
}

[data-theme='Christmas'] {
  --font-family-primary: 'Mountains of Christmas', 'Open Sans', 'Arial', sans-serif;
  --font-family-secondary: 'Roboto', 'Open Sans', 'Arial', sans-serif;
  --background-theme-color: #c8e6c9;
  --color-primary: #ff5630;
  --color-secondary: #36b37e;
  --border-width: 2px;
  --dark: #2b2b2b;

  --border-radius: 8px;
  --border-style: solid;
  --border-color: #36b37e;
  --p-font-family: 'Open Sans', sans-serif;

  --main-container-margin: -100px auto 30px auto;
}

[data-theme='Holiday'] {
  --font-family-primary: 'Berkshire Swash', 'Open Sans', 'Arial', sans-serif;
  --font-family-secondary: 'Roboto', 'Open Sans', 'Arial', sans-serif;
  --background-theme-color: #b3e5fc;
  --color-primary: #29b6f6;
  --color-secondary: #00acc1;
  --border-width: 2px;
  --dark: #2b2b2b;

  --border-radius: 8px;
  --border-style: solid;
  --border-color: #29b6f6;
  --p-font-family: 'Open Sans', sans-serif;

  --main-container-margin: -100px auto 30px auto;
}

[data-theme='Science'] {
  --font-family-primary: 'Share Tech', 'Open Sans', 'Arial', sans-serif;
  --font-family-secondary: 'Roboto', 'Open Sans', 'Arial', sans-serif;
  --background-theme-color: #e0f2f1;
  --color-primary: #41b1d8;
  --color-secondary: #f57f17;
  --border-width: 2px;
  --dark: #2b2b2b;

  --border-radius: 8px;
  --border-style: solid;
  --border-color: #f57f17;
  --p-font-family: 'Open Sans', sans-serif;

  --main-container-margin: -100px auto 30px auto;
}

[data-theme='Easter'] {
  --font-family-primary: 'Lemon', 'Open Sans', 'Arial', sans-serif;
  --font-family-secondary: 'Roboto', 'Open Sans', 'Arial', sans-serif;
  --background-theme-color: #b2dfdb;
  --color-primary: #00bfa5;
  --color-secondary: #26c6da;
  --border-width: 2px;
  --dark: #2b2b2b;

  --border-radius: 8px;
  --border-style: solid;
  --border-color: #26a69a;
  --p-font-family: 'Open Sans', sans-serif;

  --main-container-margin: -100px auto 30px auto;
}

[data-theme='StPatrick'] {
  --font-family-primary: 'Bubblegum Sans', 'Open Sans', 'Arial', sans-serif;
  --font-family-secondary: 'Roboto', 'Open Sans', 'Arial', sans-serif;
  --background-theme-color: #558b2f;
  --color-primary: #558b2f;
  --color-secondary: #f9a825;
  --border-width: 2px;
  --dark: #2b2b2b;

  --border-radius: 8px;
  --border-style: solid;
  --border-color: #f9a825;
  --p-font-family: 'Open Sans', sans-serif;

  --main-container-margin: -100px auto 30px auto;
}

[data-theme='Ramadan'] {
  --font-family-primary: 'Hedvig Letters Serif', 'Open Sans', 'Arial', sans-serif;
  --font-family-secondary: 'Roboto', 'Open Sans', 'Arial', sans-serif;
  --background-theme-color: #ffecb3;
  --color-primary: #0277BD;
  --color-secondary: #F9A825;
  --border-width: 2px;
  --dark: #2b2b2b;

  --border-radius: 8px;
  --border-style: solid;
  --border-color: #F9A825;
  --p-font-family: 'Open Sans', sans-serif;

  --main-container-margin: -100px auto 30px auto;
}

/* Responsiveness */
@media only screen and (max-width: 800px) {
  :root {
    --main-container-padding-left: 13px;
    --main-container-padding-right: 13px;
  }
}

@media only screen and (max-width: 600px) and (max-height: 999px) {
  :root {
    --main-container-padding-top: 15px;
    --main-container-padding-bottom: 15px;

    --plate-padding-left: 7.8%;
    --plate-padding-top: 1.5%;
    --plate-padding-right: 7.8%;
    --plate-padding-bottom: 5%;

    --p-font-size: 0.79rem;
    --h1-font-size: 1.75rem;
    --h2-font-size: 1.4rem;
    --h3-font-size: 1.25rem;
    --h4-font-size: 1.15rem;
    --h5-font-size: 1rem;
    --h6-font-size: 0.79rem;
  }
}
